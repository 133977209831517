import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaStar, FaFacebookF, FaInstagram } from 'react-icons/fa';
import { main, reviews, social, formTitle } from './InternalSidebar.module.css';
import useCustomerReviews from '../hooks/useCustomerReviews';
import GetQuoteForm from './GetQuoteForm';

export default ({
    customerReview = true,
    quotation = true
}) => {
    const { t } = useTranslation();

    const customerReviews = useCustomerReviews();

    const randomReview = useMemo(() => {
        return customerReviews[Math.floor(Math.random() * customerReviews.length)]
    }, [customerReviews]);

    return (
        <aside className={main}>
            {(customerReview && randomReview) && (
                <div className={reviews}>
                    <div>
                        {Array.from({ length: randomReview.rating }).map((_, ii) => (
                            <FaStar key={ii} color="#FAA61A" />
                        ))}
                    </div>
                    <span>{randomReview.text}</span>
                    <strong>{randomReview.author_name}</strong>
                </div>
            )}
            {quotation && (
                <div>
                    <h2 className={formTitle}>{t('home.getQuoteForm.title')}</h2>
                    <GetQuoteForm flavor="solid"/>
                </div>
            )}
            <div className={social}>
                <a
                    href="https://www.facebook.com/FlashMovesAustralia/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaFacebookF size={'2em'} style={{ cursor: 'pointer' }}/>
                </a>
                <a
                    href="https://www.instagram.com/flashmoves/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaInstagram size={'2em'} style={{ cursor: 'pointer' }}/>
                </a>
            </div>
        </aside>
    );
}
