import React from "react";
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby';
import { main, heroContent, hero } from './InternalSection.module.css';
import { resetLink } from '../styles/utils.module.css';
import BackgroundImage from './BackgroundImage';
import useImages from '../hooks/useImages';
import { findImageData } from '../utils/tools';

export default ({ children, content }) => {
    const images = useImages();

    return (
        <section className={main}>
            <Helmet>
                <title>Flash Moves Transport Solutions - {content.header.title}</title>
            </Helmet>
            <div className={hero}>
                <BackgroundImage opacity={0.4} imageData={findImageData(images, content.hero.image)} />
                <div className={`${heroContent} ${resetLink}`}>
                    <h2>{content.hero.title}</h2>
                    {content.hero.headline.split('\n').map((segment, index) => <h1 key={index}>{segment}</h1>)}
                    <strong>
                        {content.hero.breadcrumbs.map((step, index) => (
                            <span key={index}>{index !== 0 && ' / '}{step.url ? (
                                <Link to={step.url}>{step.label}</Link>
                            ) : step.label}</span>
                        ))}
                    </strong>
                </div>
            </div>
            {children}
        </section>
    );
}
